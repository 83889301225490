import styles from './Preview.module.scss';

export interface PreviewProps {
	url: string;
	inPreview: string;
	exitPreview: string;
	children?: React.ReactNode;
}

export const Preview: React.FC<PreviewProps> = ({ children, url, inPreview, exitPreview }) => (
	<div className={styles.Preview}>
		<div className={styles.Preview_top}>
			<p>
				{inPreview}{' '}
				<a className={styles.Preview_link} href={`/api/exitPreview?slug=${url}`}>
					{exitPreview}
				</a>
			</p>
		</div>
		<div className={styles.Preview_content}>{children}</div>
	</div>
);
