export type ServiceInfoKey =
	| 'CookiesVideoPermission'
	| 'FetchingData'
	| 'FullyBooked'
	| 'ExpectedWaitingTime'
	| 'HistoricNotApplicable'
	| 'ListenToThisPageWithWebReader'
	| 'NoJobs'
	| 'NoNews'
	| 'NoPublications'
	| 'NoWebinars'
	| 'SkipToMain'
	| 'WeAreOpenUntil'
	| 'WeOpen$Clock$Time'
	| 'WeOpen$Day$Clock$Time';

interface DictionaryFunctions {
	get(key: ServiceInfoKey): string;
}

export const ServiceInfoItems = (getValue: (key: string, replacements?: string[], defaultValue?: string) => string): DictionaryFunctions => {
	return {
		get: (key: ServiceInfoKey): string => {
			switch (key) {
				case 'CookiesVideoPermission':
					return getValue(
						'ServiceInfo.CookiesVideoPermission',
						null,
						'For at afspille videoen beder vi om din tilladelse til at bruge cookies.',
					);
				case 'FetchingData':
					return getValue('ServiceInfo.FetchingData', null, 'Henter data...');
				case 'FullyBooked':
					return getValue('ServiceInfo.FullyBooked', null, 'Fuldt booket');
				case 'ExpectedWaitingTime':
					return getValue('ServiceInfo.ExpectedWaitingTime', null, 'Forventet ventetid');
				case 'HistoricNotApplicable':
					return getValue('ServiceInfo.HistoricNotApplicable', null, 'Historisk - ikke gældende');
				case 'ListenToThisPageWithWebReader':
					return getValue('ServiceInfo.ListenToThisPageWithWebReader', null, 'Lyt til denne side med ReadSpeaker webReader');
				case 'NoJobs':
					return getValue('ServiceInfo.NoJobs', null, 'Der er ingen ledige stillinger i øjeblikket.');
				case 'NoNews':
					return getValue('ServiceInfo.NoNews', null, 'Der er ingen nyheder i øjeblikket.');
				case 'NoPublications':
					return getValue('ServiceInfo.NoPublications', null, 'Der er ingen publikationer i øjeblikket.');
				case 'NoWebinars':
					return getValue('ServiceInfo.NoWebinars', null, 'Der er ingen webinarer i øjeblikket.');
				case 'SkipToMain':
					return getValue('ServiceInfo.SkipToMain', null, 'Klik for at skippe navigation og gå direkte til indhold');
				case 'WeAreOpenUntil':
					return getValue('ServiceInfo.WeAreOpenUntil', null, 'Vi er åbne til');
				case 'WeOpen$Clock$Time':
					return getValue('ServiceInfo.WeOpen$Clock$Time', null, 'Vi åbner {value1} {value2}');
				case 'WeOpen$Day$Clock$Time':
					return getValue('ServiceInfo.WeOpen$Day$Clock$Time', null, 'Vi åbner {value1} {value2} {value3}');
			}
		},
	};
};
