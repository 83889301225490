export type LabelKey =
	| 'Area'
	| 'All'
	| 'Before'
	| 'BreadcrumbNavigation'
	| 'Category'
	| 'City'
	| 'CurrentPage'
	| 'DateRangePlaceholder'
	| 'Deadline'
	| 'Email'
	| 'Friday'
	| 'HistoricalArchive'
	| 'JobCategory'
	| 'Menu'
	| 'Minutes'
	| 'Monday'
	| 'Name'
	| 'NextMonth'
	| 'NextPage'
	| 'Notice'
	| 'NumberOfResultsPerPage'
	| 'OutOf'
	| 'Page'
	| 'Pages'
	| 'PreviousMonth'
	| 'PreviousPage'
	| 'Publication'
	| 'RelatedNews'
	| 'RelatedPages'
	| 'RelatedPublications'
	| 'ResultsPerPage'
	| 'Saturday'
	| 'Sunday'
	| 'ThePageDoesNotExistSearchHeading'
	| 'Tuesday'
	| 'Title'
	| 'Thursday'
	| 'Tomorrow'
	| 'TotalResults'
	| 'Type'
	| 'ValidFrom'
	| 'ValidTo'
	| 'Wednesday'
	| 'Question'
	| 'ZipCode';

interface DictionaryFunctions {
	get(key: LabelKey): string;
}

export const LabelItems = (getValue: (key: string, replacements?: string[], defaultValue?: string) => string): DictionaryFunctions => {
	return {
		get: (key: LabelKey): string => {
			switch (key) {
				case 'Area':
					return getValue('Labels.Area', null, 'Område');
				case 'All':
					return getValue('Labels.All', null, 'Alle');
				case 'Before':
					return getValue('Labels.Before', null, 'Inden');
				case 'BreadcrumbNavigation':
					return getValue('Labels.BreadcrumbNavigation', null, 'Brødkrumme navigation');
				case 'Category':
					return getValue('Labels.Category', null, 'Kategori');
				case 'City':
					return getValue('Labels.City', null, 'By');
				case 'CurrentPage':
					return getValue('Labels.CurrentPage', null, 'Nuværende side');
				case 'DateRangePlaceholder':
					return getValue('Labels.DateRangePlaceholder', null, 'Indtast dato interval');
				case 'Deadline':
					return getValue('Labels.Deadline', null, 'Ansøgningsfrist');
				case 'Email':
					return getValue('Labels.Email', null, 'E-mail');
				case 'Friday':
					return getValue('Labels.Friday', null, 'Fredag');
				case 'HistoricalArchive':
					return getValue('Labels.HistoricalArchive', null, 'Historisk arkiv');
				case 'JobCategory':
					return getValue('Labels.JobCategory', null, 'Jobkategori');
				case 'Menu':
					return getValue('Labels.Menu', null, 'Menu');
				case 'Minutes':
					return getValue('Labels.Minutes', null, 'Minutter');
				case 'Monday':
					return getValue('Labels.Monday', null, 'Mandag');
				case 'Name':
					return getValue('Labels.Name', null, 'Navn');
				case 'NextMonth':
					return getValue('Labels.NextMonth', null, 'Næste måned');
				case 'NextPage':
					return getValue('Labels.NextPage', null, 'Næste side');
				case 'Notice':
					return getValue('Labels.Notice', null, 'Bemærk');
				case 'NumberOfResultsPerPage':
					return getValue('Labels.NumberOfResultsPerPage', null, 'Antal resultater pr. side');
				case 'OutOf':
					return getValue('Labels.OutOf', null, 'Ud af');
				case 'Page':
					return getValue('Labels.Page', null, 'Side');
				case 'Pages':
					return getValue('Labels.Pages', null, 'Sider');
				case 'PreviousMonth':
					return getValue('Labels.PreviousMonth', null, 'Forrige måned');
				case 'PreviousPage':
					return getValue('Labels.PreviousPage', null, 'Forrige side');
				case 'Publication':
					return getValue('Labels.Publication', null, 'Publikation');
				case 'RelatedNews':
					return getValue('Labels.RelatedNews', null, 'Relaterede nyheder');
				case 'RelatedPages':
					return getValue('Labels.RelatedPages', null, 'Relaterede sider');
				case 'RelatedPublications':
					return getValue('Labels.RelatedPublications', null, 'Relaterede publikationer');
				case 'ResultsPerPage':
					return getValue('Labels.ResultsPerPage', null, 'Resultater pr. side');
				case 'Saturday':
					return getValue('Labels.Saturday', null, 'Lørdag');
				case 'Sunday':
					return getValue('Labels.Sunday', null, 'Søndag');
				case 'ThePageDoesNotExistSearchHeading':
					return getValue('Labels.ThePageDoesNotExistSearchHeading', null, 'Siden findes ikke');
				case 'Title':
					return getValue('Labels.Title', null, 'Titel');
				case 'TotalResults':
					return getValue('Labels.TotalResults', null, 'Resultater i alt');
				case 'Tuesday':
					return getValue('Labels.Tuesday', null, 'Tirsdag');
				case 'Thursday':
					return getValue('Labels.Thursday', null, 'Torsdag');
				case 'Tomorrow':
					return getValue('Labels.Tomorrow', null, 'I morgen');
				case 'Type':
					return getValue('Labels.Type', null, 'Type');
				case 'ValidFrom':
					return getValue('Labels.ValidFrom', null, 'Gyldig fra');
				case 'ValidTo':
					return getValue('Labels.ValidTo', null, 'Gyldig til');
				case 'Wednesday':
					return getValue('Labels.Wednesday', null, 'Onsdag');
				case 'Question':
					return getValue('Labels.Question', null, 'Spørgsmål');
				case 'ZipCode':
					return getValue('Labels.ZipCode', null, 'Postnr.');
			}
		},
	};
};
