export type ErrorKey = 'CatalogPagination' | 'PlateOperators';

interface DictionaryFunctions {
	get(key: ErrorKey): string;
}

export const ErrorItems = (getValue: (key: string, replacements?: string[], defaultValue?: string) => string): DictionaryFunctions => {
	return {
		get: (key: ErrorKey): string => {
			switch (key) {
				case 'CatalogPagination':
					return getValue('Errors.CatalogPagination', null, 'Indtast et nummer inden for antallet af sider');
				case 'PlateOperators':
					return getValue('Errors.PlateOperators', null, 'Vi kunne desværre ikke finde nogen nummerpladeoperatør i byen/postnummeret.');
			}
		},
	};
};
