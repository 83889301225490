import { useRouter } from 'next/router';
import React, { createContext, useContext, useEffect, useState } from 'react';

interface HistoryContextProps {
	history: { previous: string; current: string };
	setHistory({ previous, current }: HistoryContextProps['history']): void;
}

const HistoryContext = createContext<HistoryContextProps>({} as HistoryContextProps);
export const HistoryProvider = ({ children }: { children: React.ReactNode }) => {
	const { asPath } = useRouter();
	const [history, setHistory] = useState<HistoryContextProps['history']>(null);
	useEffect(() => {
		if (history?.current === asPath) return;
		setHistory((previous) => {
			return { previous: previous?.current, current: asPath };
		});
	}, [asPath, history]);

	return (
		<HistoryContext.Provider
			value={{
				history,
				setHistory,
			}}
		>
			{children}
		</HistoryContext.Provider>
	);
};

export function useHistory(): HistoryContextProps {
	const context = useContext(HistoryContext);
	return context;
}
