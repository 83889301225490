import React, { useContext } from 'react';

export const SiteContext = React.createContext<Partial<Models.Site>>({ host: 'localhost', culture: 'en-US' });

export interface SiteContextProviderProps {
	site: Models.Site;
	children?: React.ReactNode;
}
export const SiteContextProvider: React.FC<SiteContextProviderProps> = ({ site, children }) => {
	return <SiteContext.Provider value={site}>{children}</SiteContext.Provider>;
};

export function useSiteContext(): Partial<Models.Site> {
	return useContext(SiteContext);
}
