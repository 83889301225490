export type DocumentKey = 'Document' | 'DocumentId' | 'DocumentPurpose' | 'DocumentNotice';

interface DictionaryFunctions {
	get(key: DocumentKey): string;
}

export const DocumentItems = (getValue: (key: string, replacements?: string[], defaultValue?: string) => string): DictionaryFunctions => {
	return {
		get: (key: DocumentKey): string => {
			switch (key) {
				case 'Document':
					return getValue('Document.Document', null, 'Blanket');
				case 'DocumentId':
					return getValue('Document.DocumentId', null, 'Blanketnr.');
				case 'DocumentPurpose':
					return getValue('Document.DocumentPurpose', null, 'Om Blanketten');
				case 'DocumentNotice':
					return getValue(
						'Document.DocumentNotice',
						null,
						`OBS! Gem blanketten på din computer,
						inden du går i gang med at udfylde den.
						Ellers risikerer du, at dine oplysninger
						ikke bliver gemt.`,
					);
			}
		},
	};
};
