import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { Preview } from 'designsystem/components/2-molecules/Preview';
import { useRouter } from 'next/router';

export interface PreviewProps {
	url: string;
	children?: React.ReactNode;
}

export const PreviewFeature: React.FC<PreviewProps> = ({ children, url }) => {
	const { isPreview } = useRouter();
	const dictionary = useDictionaryContext();

	if (!isPreview) return <>{children}</>;

	return (
		<Preview url={url} inPreview={dictionary.getPreview('InPreview')} exitPreview={dictionary.getPreview('ExitPreview')}>
			{children}
		</Preview>
	);
};
