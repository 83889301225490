export type NewsletterKey = 'ApiError' | 'SelectNewsletters' | 'NoNewsletterSelected' | 'Subscribe' | 'SignupSuccess';

interface DictionaryFunctions {
	get(key: NewsletterKey): string;
}

export const NewsletterItems = (getValue: (key: string, replacements?: string[], defaultValue?: string) => string): DictionaryFunctions => {
	return {
		get: (key: NewsletterKey): string => {
			switch (key) {
				case 'ApiError':
					return getValue('Newsletter.ApiError', null, 'Der er opstået en fejl.');
				case 'NoNewsletterSelected':
					return getValue('Newsletter.NoNewsletterSelected', null, 'Du skal vælge mindst ét nyhedsbrev.');
				case 'Subscribe':
					return getValue('Newsletter.Subscribe', null, 'Tilmeld');
				case 'SignupSuccess':
					return getValue('Newsletter.SignupSuccess', null, 'Du er nu tilmeldt de valgte nyhedsbreve.');
				case 'SelectNewsletters':
					return getValue('Newsletter.SelectNewsletters', null, 'Vælg dit abonnement');
			}
		},
	};
};
