export type QuizKey = 'CorrectAnswer' | 'CorrectAnswers' | 'NumberOfQuestions' | 'WrongAnswer' | 'YouHaveCompletedQuizAndReceived';

interface DictionaryFunctions {
	get(key: QuizKey): string;
}

export const QuizItems = (getValue: (key: string, replacements?: string[], defaultValue?: string) => string): DictionaryFunctions => {
	return {
		get: (key: QuizKey): string => {
			switch (key) {
				case 'CorrectAnswers':
					return getValue('Quiz.CorrectAnswers', null, 'Rigtige svar');
				case 'CorrectAnswer':
					return getValue('Quiz.CorrectAnswer', null, 'Svaret er rigtigt');
				case 'NumberOfQuestions':
					return getValue('Quiz.NumberOfQuestions', null, 'Antal spørgsmål');
				case 'WrongAnswer':
					return getValue('Quiz.WrongAnswer', null, 'Svaret er forkert');
				case 'YouHaveCompletedQuizAndReceived':
					return getValue('Quiz.YouHaveCompletedQuizAndReceived', null, 'Du har nu gennemgået quizzen og fik');
			}
		},
	};
};
