export type AbbreviationKey = 'Approximately' | 'Clock';

interface DictionaryFunctions {
	get(key: AbbreviationKey): string;
}

export const AbbreviationItems = (getValue: (key: string, replacements?: string[], defaultValue?: string) => string): DictionaryFunctions => {
	return {
		get: (key: AbbreviationKey): string => {
			switch (key) {
				case 'Approximately':
					return getValue('Abbreviations.Approximately', null, 'Ca.');
				case 'Clock':
					return getValue('Abbreviations.Clock', null, 'Kl.');
			}
		},
	};
};
