export type SearchKey = 'Disclaimer' | 'Placeholder' | 'Search' | 'SearchModal' | 'SearchWord' | 'TrySearch';

interface DictionaryFunctions {
	get(key: SearchKey): string;
}

export const SearchItems = (getValue: (key: string, replacements?: string[], defaultValue?: string) => string): DictionaryFunctions => {
	return {
		get: (key: SearchKey): string => {
			switch (key) {
				case 'Disclaimer':
					return getValue(
						'Search.Disclaimer',
						null,
						'Skriv ikke personlige oplysninger (fx dit cpr-nr.). Vi gemmer søgninger til statistik, så vi løbende kan forbedre søgefunktionen.',
					);
				case 'Placeholder':
					return getValue('Search.Placeholder', null, 'Hvad søger du?');
				case 'Search':
					return getValue('Search.Search', null, 'Søg');
				case 'SearchModal':
					return getValue('Search.SearchModal', null, 'Søge Modal');
				case 'SearchWord':
					return getValue('Search.SearchWord', null, 'Søgeord');
				case 'TrySearch':
					return getValue('Search.TrySearch', null, 'Prøv eventuelt at søge');
			}
		},
	};
};
