export type PreviewKey = 'InPreview' | 'ExitPreview';

interface DictionaryFunctions {
	get(key: PreviewKey): string;
}

export const PreviewItems = (getValue: (key: string, replacements?: string[], defaultValue?: string) => string): DictionaryFunctions => {
	return {
		get: (key: PreviewKey): string => {
			switch (key) {
				case 'InPreview':
					return getValue('Preview.InPreview', null, 'Preview synlig');
				case 'ExitPreview':
					return getValue('Preview.ExitPreview', null, 'Luk Preview');
			}
		},
	};
};
