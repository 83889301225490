export type ActionKey =
	| 'Back'
	| 'BackToOverview'
	| 'Choose'
	| 'Close'
	| 'ContactUs'
	| 'Get'
	| 'GoTo'
	| 'GoToContent'
	| 'GoToHomePage'
	| 'LogOn'
	| 'LogOff'
	| 'Next'
	| 'Open'
	| 'ReadAloud'
	| 'ReadMore'
	| 'ReadMoreAbout'
	| 'Register'
	| 'RemoveFilter'
	| 'RenewCookieAccept'
	| 'Restart'
	| 'SeePrivacyPolicy'
	| 'Show'
	| 'Start'
	| 'SubscribeWithRSS';

interface DictionaryFunctions {
	get(key: ActionKey): string;
}

export const ActionItems = (getValue: (key: string, replacements?: string[], defaultValue?: string) => string): DictionaryFunctions => {
	return {
		get: (key: ActionKey): string => {
			switch (key) {
				case 'Back':
					return getValue('Actions.Back', null, 'Tilbage');
				case 'BackToOverview':
					return getValue('Actions.BackToOverview', null, 'Tilbage til oversigt');
				case 'Choose':
					return getValue('Actions.Choose', null, 'Vælg');
				case 'Close':
					return getValue('Actions.Close', null, 'Luk');
				case 'ContactUs':
					return getValue('Actions.ContactUs', null, 'Ring til os');
				case 'ReadMore':
					return getValue('Actions.ReadMore', null, 'Læs mere');
				case 'ReadMoreAbout':
					return getValue('Actions.ReadMoreAbout', null, 'Læs mere om');
				case 'Get':
					return getValue('Actions.Get', null, 'Hent');
				case 'GoTo':
					return getValue('Actions.GoTo', null, 'Gå til');
				case 'GoToContent':
					return getValue('Actions.GoToContent', null, 'Gå til indhold');
				case 'GoToHomePage':
					return getValue('Actions.GoToHomePage', null, 'Gå til forsiden');
				case 'LogOn':
					return getValue('Actions.LogOn', null, 'Log på');
				case 'LogOff':
					return getValue('Actions.LogOff', null, 'Log af');
				case 'Next':
					return getValue('Actions.Next', null, 'Næste');
				case 'Open':
					return getValue('Actions.Open', null, 'Åben');
				case 'ReadAloud':
					return getValue('Actions.ReadAloud', null, 'Læs op');
				case 'Register':
					return getValue('Actions.Register', null, 'Tilmeld');
				case 'RemoveFilter':
					return getValue('Actions.RemoveFilter', null, 'Fjern filter');
				case 'RenewCookieAccept':
					return getValue('Actions.RenewCookieAccept', null, 'Forny cookie tilladelse');
				case 'Restart':
					return getValue('Actions.Restart', null, 'Start forfra');
				case 'SeePrivacyPolicy':
					return getValue('Actions.SeePrivacyPolicy', null, 'Se privatlivspolitik.');
				case 'Show':
					return getValue('Actions.Show', null, 'Vis');
				case 'Start':
					return getValue('Actions.Start', null, 'Start');
				case 'SubscribeWithRSS':
					return getValue('Actions.SubscribeWithRSS', null, 'Abonner med RSS');
			}
		},
	};
};
