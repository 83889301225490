import { DictionaryContextProvider } from 'application/contexts/DictionaryContext';
import { HistoryProvider } from 'application/contexts/HistoryContext';
import { PageContextProvider } from 'application/contexts/PageContext';
import { SiteContextProvider } from 'application/contexts/SiteContext';
import { NextPage } from 'next';
import type { AppProps as NextAppProps } from 'next/app';
import { StrictMode } from 'react';

import 'ui/styles/globals.scss';

/**
 * The `DXPContentPage` type is the result of _app.tsx's modifications to page props.
 */
export type DXPContentPage<TProps extends { content?: Content.PageContent }> = NextPage<TProps['content'] & Omit<TProps, 'content' | 'session'>>;

const App: React.FC<NextAppProps> = ({ Component, pageProps }) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const { content, dictionary, site, host } = (pageProps as any) ?? {};
	const { page } = content ?? {};
	const { culture } = page ?? {};
	return (
		<StrictMode>
			<SiteContextProvider site={site}>
				<DictionaryContextProvider dictionary={dictionary ?? []} culture={culture}>
					<HistoryProvider>
						<PageContextProvider content={content} host={host}>
							<Component {...content} {...pageProps} />
						</PageContextProvider>
					</HistoryProvider>
				</DictionaryContextProvider>
			</SiteContextProvider>
		</StrictMode>
	);
};

export default App;
