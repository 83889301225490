import { PageContextProps } from 'application/contexts/PageContext';
import { queryCmsContent } from './_api';

export const getDictionaryFromCms = async (pageContext: Partial<PageContextProps>): Promise<Content.DictionaryItem[]> => {
	const query = `{
		dictionary {
			key
			values {
				culture
				value
			}
		}
	}`;

	const dictionary: { dictionary: Content.DictionaryItem[] } = await queryCmsContent({
		query,
		pageContext,
		requestType: 'dictionary',
	});

	return dictionary?.dictionary;
};
