import { PageContextProps } from 'application/contexts/PageContext';

export const getHostNameHeader = ({
	pageContext,
	envs,
}: {
	pageContext: Partial<PageContextProps>;
	envs: { DOMAIN_PROTOCOL: string; DOMAIN_PORT: string };
}) => {
	const { host } = pageContext ?? {};
	const { DOMAIN_PROTOCOL: protocol, DOMAIN_PORT: port } = envs ?? {};
	if (host?.startsWith('http')) return host;
	return `${protocol ? `${protocol}://` : ''}${host}${host?.endsWith(port) ? '' : port ? ':' + port : ''}/`;
};
